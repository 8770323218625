window.addEventListener("load", () => {
    // gsap.registerPlugin(Flip)

    function videoControl() {
        const video = document.querySelector(".promo__video");
        const playBtn = document.querySelector(".promo__play");
        video.play()
        playBtn.addEventListener("click", () => {
            video.play();
            playBtn.classList.add("hidden");
        });
        video.addEventListener("click", () => {
            if (video.paused) {
                return;
            }
            video.pause();
            playBtn.classList.remove("hidden");
        });
    }

    

    function toggleList() {
        const btn = document.querySelectorAll(".services__btn"),
            container = document.querySelectorAll(".services__sublist");

        btn.forEach((item, i) => {
            let toggleBlock = gsap.to(container[i], {
                height: "auto",
                paused: true,
                autoAlpha: 1,
                duration: 0.5,
                ease: "power1.out",
                onComplete: () => {
                    ScrollTrigger.refresh();
                    ScrollTrigger.update();
                },
                onReverseComplete: () => {
                    ScrollTrigger.refresh();
                    ScrollTrigger.update();
                },
            });

            btn[i].addEventListener("click", () => {
                btn[i].classList.toggle("open");
                container[i].classList.toggle("show");
                container[i].classList.contains("show") ? toggleBlock.play() : toggleBlock.reverse();
            });
        });
    }

    toggleList();


    // function philosophyImg() {
    //   const arrImg = gsap.utils.toArray('.philosophy__img-box img')
    //   const counter = {
    //           count: 0
    //       }
    //   gsap.to(counter, {
    //       scrollTrigger: {
    //           trigger: '.philosophy__img-box',
    //           start: 'top 10%',
    //           endTrigger: '.philosophy__inner',
    //           end: 'bottom center',
    //           scrub: true,
    //           pin: true,
    //       },
    //       onUpdate: () => {
    //           toggleImg()
    //       },
    //       ease: 'linear',
    //       snap: {
    //           count: 1
    //       },
    //       count: arrImg.length - 1
    //   })
//
//     function toggleImg() {
//         if(arrImg[counter.count].classList.contains('show')) {
//             return
//         }
//         arrImg.forEach(item => {
//             item.classList.remove('show')
//         })
//         arrImg[counter.count].classList.add('show')
//     }
//     toggleImg()
// }


  if(window.innerWidth > 576) {
    gsap.to('.promo__video-wrapper', {
        scrollTrigger: {
            trigger: '.promo',
            start: "top top",
            // endTrigger: '.promo',
            end: "bottom top",
            scrub: 1,
            pinSpacing: true,
            // pinSpacer: '.promo',
            pin: '.promo__video-wrapper',
            // pinnedContainer: '.promo',
        },
        ease: "power1.out",
        scale: 1,
    });
}

//   if(window.innerWidth > 768) {
//     philosophyImg();
// }

    gsap.to('.philosophy__text p span', {
        scrollTrigger: {
            trigger: '.philosophy__text',
            start: "top 95%",
            end: "bottom center",
            scrub: 2,
        },
        stagger: {
            each: 0.5
        },
        ease: "power1.out",
        backgroundPositionX: "-100%",
    });
    const tlShowLine = gsap.timeline({
        defaults: {
            ease: "power1.out"
        },
    });
    tlShowLine.to(".philosophy__img svg path", {
        scrollTrigger: {
            trigger: '.philosophy__img',
            start: "top 50%",
            end: "bottom top",
            scrub: 2,
        },
        strokeDashoffset: 0,
        autoAlpha: 1
    });

    gsap.utils.toArray(".works__grid-item").forEach((item, i) => {
        gsap.from(item, {
            scrollTrigger: {
                trigger: item,
                start: 'top bottom',
                end: 'center center',
                scrub: i%2 + 0.5
            },
            ease: 'sine.inOut',
            y: 100,
            opacity: 0.2
        })
    })


    function worksMouseHandler() {
        const wrapper = document.querySelector('.works__wrapper')
        const follower = document.querySelector('.works__follower')
        const imgBox = document.querySelector('.works__img-box')
        const wrapperHeight = wrapper.offsetHeight
        const imgBoxTrack = imgBox.offsetHeight - imgBox.children[0].offsetHeight

        const cursor = {
            x: 0,
            y: 0
        }
        let progressWrapper

        let xTo = gsap.quickTo(follower, "x", {duration: 0.8, ease: 'power3'})
        let yTo = gsap.quickTo(follower, "y", {duration: 0.8, ease: 'power3'})
        let imgBoxTo = gsap.quickTo('.works__img-box', "y", {duration: 0.8, ease: 'power3'})

        wrapper.addEventListener('mousemove', (e) => {

            coords = wrapper.getBoundingClientRect();

            cursor.y = e.clientY - coords.top
            cursor.x = (e.clientX - coords.left) / 10
            // console.log(cursor);
            progressWrapper = 100 / (wrapperHeight / cursor.y)

            xTo(cursor.x)
            yTo(cursor.y)
            imgBoxTo(-(imgBoxTrack / 100) * progressWrapper)

        })
        wrapper.addEventListener('mouseenter', (e) => {
            gsap.to(follower, {
                scale: 1,
                duration: 0.4,
                ease: 'power3'
            })

        })
        wrapper.addEventListener('mouseleave', (e) => {
            gsap.to(follower, {
                scale: 0,
                duration: 0.4,
                ease: 'power3'
            })
        })
    }

    worksMouseHandler()


    const servicesPathLength = document.querySelector(".services__btn svg path").getTotalLength().toFixed()
    gsap.set(".services__btn svg path", {
        strokeDasharray: servicesPathLength,
        strokeDashoffset: servicesPathLength
    })

    const worksPathLength = document.querySelector(".works__link svg path").getTotalLength().toFixed()
    gsap.set(".works__link svg path", {
        strokeDasharray: worksPathLength,
        strokeDashoffset: worksPathLength
    })

    // const headerPathLength = document.querySelector(".header__btn svg path").getTotalLength().toFixed()
    // gsap.set(".header__btn svg path", {
    //   strokeDasharray: worksPathLength,
    //   strokeDashoffset: worksPathLength
    // })


    //     let a = document.querySelector("#svg-obj");
    // // Get the SVG document inside the Object tag
    //     let svgDoc = a.contentDocument;
    // // Get one of the SVG items by ID;
    //     let paths = svgDoc.querySelectorAll("path");
    //     console.log(paths);
    //     paths.forEach(path => {
    //       gsap.set(path, {
    //         strokeDasharray: path.getTotalLength().toFixed(),
    //         strokeDashoffset: path.getTotalLength().toFixed()
    //       })
    //     })


    const showScreenTl = gsap.timeline({
        defaults: {
            duration: 1.5,
            ease: "power3.out",
        },
        paused: true
    })

    showScreenTl.from('.header', {opacity: 0})
        .from('.promo', {opacity: 0}, '<')
        .to('.promo__greeting svg path', {strokeDashoffset: 0}, '<');




    gsap.to('.preloader', {
        duration: 1,
        autoAlpha: 0,
        onComplete: function() {
            showScreenTl.play(0);
            videoControl();
            this.targets()[0].classList.add('hidden');
            document.querySelector('body').style.overflowY = 'visible';
        }
    })
});


